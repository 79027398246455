/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { NextApiRequest, NextApiResponse } from 'next';
import { parseCookies, setCookie, destroyCookie } from 'nookies';

export const COOKIES_NAME = {
  LIVE: 'RTLCFG', // Liveドメイン用
  PLAYER: 'STPLAYERCFG', // 共通Player用
} as const;
const SPLIT_STR = '&';
const CONNECT_STR = ',';
const MAX_AGE = 24 * 60 * 60 * 30;

const { NEXT_PUBLIC_COOKIE_DOMAIN } = process.env;

const convertToObject = (encodedValue: string): { [key: string]: string } | null => {
  try {
    const decodedValues = decodeURIComponent(encodedValue).split(SPLIT_STR);

    return encodedValue
      ? (Object.fromEntries(
          decodedValues.map((value) => value.split('=')) as Iterable<readonly [PropertyKey, unknown]>,
        ) as Record<string, string>)
      : null;
  } catch (_error) {
    return null;
  }
};

export type CookieTypes = typeof COOKIES_NAME[keyof typeof COOKIES_NAME];
type GetConfigTypes = {
  key: string;
  type?: CookieTypes;
  defaultValue?: string | Array<string>;
  req?: NextApiRequest;
};

type SetConfigTypes = {
  key: string;
  value?: string;
  domain?: string;
  path?: string;
  maxAge?: number;
  type?: CookieTypes;
  req?: NextApiRequest;
  res?: NextApiResponse;
};

const CookieUtils = {
  get: ({ key, defaultValue = '', req = null }: GetConfigTypes): string =>
    parseCookies(req ? { req } : null)[key] || (defaultValue as string),
  getFromObject: ({ key, type = COOKIES_NAME.LIVE, defaultValue = '', req = null }: GetConfigTypes): string =>
    convertToObject(parseCookies(req ? { req } : null)[type])?.[key] || (defaultValue as string),
  getArrayFromObject: ({
    key,
    type = COOKIES_NAME.LIVE,
    defaultValue = [],
    req = null,
  }: GetConfigTypes): Array<string> =>
    convertToObject(parseCookies(req ? { req } : null)[type])?.[key]?.split(CONNECT_STR) ||
    (defaultValue as Array<string>),
  set: ({
    key,
    value,
    domain = NEXT_PUBLIC_COOKIE_DOMAIN,
    path = '/',
    maxAge = MAX_AGE,
    res = null,
  }: SetConfigTypes): void => {
    setCookie(res ? { res } : null, key, value, {
      maxAge,
      domain,
      path,
    });
  },
  setToObject: ({
    key,
    value,
    domain = NEXT_PUBLIC_COOKIE_DOMAIN,
    path = '/',
    maxAge = MAX_AGE,
    type = COOKIES_NAME.LIVE,
    req = null,
    res = null,
  }: SetConfigTypes): void => {
    const prevCookies = parseCookies(req ? { req } : null)[type];
    setCookie(
      res ? { res } : null,
      type,
      new URLSearchParams({ ...convertToObject(prevCookies), [key]: value }).toString(),
      {
        maxAge,
        domain,
        path,
      },
    );
  },
  setArrayToObject: ({
    key,
    value,
    domain = NEXT_PUBLIC_COOKIE_DOMAIN,
    path = '/',
    maxAge = MAX_AGE,
    type = COOKIES_NAME.LIVE,
    req = null,
    res = null,
  }: SetConfigTypes): void => {
    const prevCookies = parseCookies(req ? { req } : null)[type];
    const newList =
      convertToObject(prevCookies) && convertToObject(prevCookies)[key]
        ? convertToObject(prevCookies)[key] + CONNECT_STR + value
        : value;
    const newObject: Record<string, string> = { ...convertToObject(prevCookies), [key]: newList };
    setCookie(res ? { res } : null, type, new URLSearchParams(newObject).toString(), {
      maxAge,
      domain,
      path,
    });
  },
  destroy: ({ key }: SetConfigTypes): void => {
    destroyCookie({}, key, { path: '/' });
  },
  destroyFromObject: ({
    key,
    domain = NEXT_PUBLIC_COOKIE_DOMAIN,
    path = '/',
    maxAge = MAX_AGE,
    type = COOKIES_NAME.LIVE,
    req = null,
    res = null,
  }: SetConfigTypes): void => {
    const prevCookies = parseCookies(req ? { req } : null)[type];
    const newObject = convertToObject(prevCookies);
    if (newObject && newObject[key]) {
      delete newObject[key];
      setCookie(res ? { res } : null, type, new URLSearchParams(newObject).toString(), {
        maxAge,
        domain,
        path,
      });
    }
  },
};

export default CookieUtils;
