import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  html,
  body {
    height: 100%;
    font-family:
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      Roboto,
      Oxygen,
      Ubuntu,
      Cantarell,
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    font-size: 16px;
  }

  body {
    overflow-x: hidden !important;
    font-size: 1rem !important;
    color: #fdfdfd !important;
    text-size-adjust: 100% !important;
    background-color: #080808 !important;
  }

  ul,
  li {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  img {
    vertical-align: bottom;
    user-select: none;
  }

  ::-webkit-media-controls {
    display: none !important;
  }

  video::-internal-media-controls-loading-panel {
    display: none !important;
  }

  video::-internal-media-controls-overflow-menu-list {
    display: none !important;
  }

  video::-webkit-media-controls {
    display: none !important;
  }

  video::-webkit-media-text-track-container {
    display: none !important;
  }

  video::-webkit-media-controls-enclosure {
    display: none !important;
  }
`;

export default GlobalStyle;
