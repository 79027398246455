import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#fdfdfd',
    },
    secondary: {
      main: '#E4E5E5',
    },
    background: {
      default: '#080808',
    },
    text: {
      primary: '#FDFDFD',
    },
    mode: 'dark',
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      '"Fira Sans"',
      '"Droid Sans"',
      '"Helvetica Neue"',
      'sans-serif',
    ].join(','),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 414,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
});

export default theme;
