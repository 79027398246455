import { createContext, useReducer, useContext, useEffect } from 'react';

import { ResDataType } from '@/pages/api/auth';
import CookieUtils from '@/modules/cookies';

const prefix = 'auth/';
export const AuthActionTypes = {
  SET_IS_AUTHENTICATED: `${prefix}SET/IS_AUTHENTICATED`,
  SET_IS_FETCHED: `${prefix}SET/IS_FETCHED`,
  SET_USERNAME: `${prefix}SET/USERNAME`,
} as const;
type AuthActionTypes = (typeof AuthActionTypes)[keyof typeof AuthActionTypes];

type State = {
  sid: string | null;
  username: string | null;
  isAuthenticated: boolean;
  ratingAge: number;
  csrfToken: string;
  isFetched: boolean;
};

const initialState: State = {
  sid: null,
  username: null,
  isAuthenticated: false,
  csrfToken: null,
  ratingAge: 18,
  isFetched: false,
};

type Action =
  | {
      type: typeof AuthActionTypes.SET_IS_AUTHENTICATED;
      payload: {
        sid: string | null;
        isAuthenticated: boolean;
        secToken: string | null;
      };
    }
  | {
      type: typeof AuthActionTypes.SET_IS_FETCHED;
      payload: {
        isFetched: boolean;
      };
    }
  | {
      type: typeof AuthActionTypes.SET_USERNAME;
      payload: {
        username: string;
      };
    };

export const AuthStateContext = createContext<State>(initialState);
export const AuthDispatchContext = createContext<React.Dispatch<Action>>(() => null);
export const useAuthDispatchContext = (): React.Dispatch<Action> =>
  useContext<React.Dispatch<Action>>(AuthDispatchContext);
export const useAuthStateContext = (): State => useContext<State>(AuthStateContext);
export const useIsAuthenticated = (): boolean => useContext<State>(AuthStateContext).isAuthenticated;

const AuthReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case AuthActionTypes.SET_IS_AUTHENTICATED: {
      const { sid, isAuthenticated, secToken } = action.payload;
      const ratingAge = isAuthenticated ? Number(CookieUtils.get({ key: 'ratingSetting', defaultValue: '18' })) : 18;
      const csrfToken = decodeURIComponent(secToken).match(/".*?"/)?.[0]?.replaceAll('"', '');

      return {
        ...state,
        sid,
        isAuthenticated,
        csrfToken,
        ratingAge,
        isFetched: true,
      };
    }
    case AuthActionTypes.SET_IS_FETCHED: {
      return {
        ...state,
        isFetched: action.payload.isFetched,
      };
    }
    case AuthActionTypes.SET_USERNAME: {
      return {
        ...state,
        username: action.payload.username,
      };
    }
    default: {
      throw new Error('no such action type');
    }
  }
};

type Props = {
  authInfo: ResDataType | null;
  children?: React.ReactNode;
};
const AuthProvider: React.FC<Props> = ({ authInfo, children }) => {
  const [state, dispatch] = useReducer(AuthReducer, initialState);

  useEffect(() => {
    if (!authInfo) return () => null;

    dispatch({
      type: AuthActionTypes.SET_IS_AUTHENTICATED,
      payload: { sid: authInfo.sid, isAuthenticated: authInfo.authencated, secToken: authInfo.secToken },
    });

    return () => {
      dispatch({
        type: AuthActionTypes.SET_IS_FETCHED,
        payload: {
          isFetched: false,
        },
      });
    };
  }, [authInfo]);

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>{children}</AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

export default AuthProvider;
