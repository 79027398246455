/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint import/prefer-default-export: 0 */
const { NEXT_PUBLIC_APP_ENV } = process.env;
const isProduction = NEXT_PUBLIC_APP_ENV === 'production';

export const commaOf = (num: string | number | undefined): string =>
  String(num ?? '').replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');

export const getContentImgPath = (contentId: number, imgType: 'main.jpg' | 'jacket.jpg'): string => {
  const { NEXT_PUBLIC_IM_URL } = process.env;
  const id = `000${contentId.toString()}`.substr(-4);

  return `${NEXT_PUBLIC_IM_URL}/content/${id.substr(1, 1) + id.substr(0, 1)}/${
    id.substr(3, 1) + id.substr(2, 1)
  }/${contentId}/${imgType}`;
};

export const base64EncodeUint8Array = (input: Uint8Array): string => {
  const keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
  let output = '';
  let chr1: number;
  let chr2: number;
  let chr3: number;
  let enc1: number;
  let enc2: number;
  let enc3: number;
  let enc4: number;
  let i = 0;
  while (i < input.length) {
    chr1 = input[i++];
    chr2 = i < input.length ? input[i++] : Number.NaN; // Not sure if the index
    chr3 = i < input.length ? input[i++] : Number.NaN; // checks are needed here
    enc1 = chr1 >> 2;
    enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
    enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
    enc4 = chr3 & 63;
    if (Number.isNaN(chr2)) {
      enc3 = 64;
      enc4 = 64;
    } else if (Number.isNaN(chr3)) {
      enc4 = 64;
    }
    output += keyStr.charAt(enc1) + keyStr.charAt(enc2) + keyStr.charAt(enc3) + keyStr.charAt(enc4);
  }

  return output;
};

export const base64DecodeUint8Array = (input: string): Uint8Array => {
  const base64 = input.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, ''); // remove <ckc></ckc>
  const jsonBase64 = JSON.parse(base64) as { rawLicenseBase64: string };
  const strBase64 = jsonBase64.rawLicenseBase64;
  const raw = window.atob(strBase64);
  // const raw = window.atob(base64);
  const rawLength = raw.length;
  const array = new Uint8Array(new ArrayBuffer(rawLength));

  for (let i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }

  return array;
};

export const convStr01ToNumber = <T>(obj: T): number | unknown[] | Record<PropertyKey, unknown> | T =>
  obj === null
    ? null
    : obj instanceof Array
    ? obj.map((v) => convStr01ToNumber(v))
    : typeof obj === 'object'
    ? Object.fromEntries(
        Object.entries(obj).map((entry): [PropertyKey, unknown] => [entry[0], convStr01ToNumber(entry[1])]),
      )
    : typeof obj === 'string' && ['0', '1'].includes(obj)
    ? Number(obj)
    : obj;

export const Logger = ((isProd: boolean) => {
  const noOp = () => null;

  return {
    log: !isProd ? console.log?.bind(console) : noOp,
    warn: !isProd ? console.warn?.bind(console) : noOp,
    debug: !isProd ? console.debug?.bind(console) : noOp,
    info: !isProd ? console.info?.bind(console) : noOp,
    error: !isProd ? console.error?.bind(console) : noOp,
  };
})(isProduction);

export type ValueOfTypes<T extends Array<string | number>> = T[number];
