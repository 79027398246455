var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"c266c1d9e1f84e143581efa1f848c7b316efdae3"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/sports/static/4.4.4/c266c1d9e1f84e143581efa1f848c7b316efdae3";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const { NEXT_PUBLIC_APP_ENV } = process.env;
const isProduction = NEXT_PUBLIC_APP_ENV === 'production';

Sentry.init({
  dsn: SENTRY_DSN || 'https://533de34ce7834a73ab57a7e922f373ed@o1009122.ingest.sentry.io/5973169',
  // Adjust this value in production, or use tracesSampler for greater control
  // tracesSampleRate: isProduction ? 0.1 : 0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  environment: NEXT_PUBLIC_APP_ENV,
});
