import GlobalStyle from '@/styles/global';

// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';

import { useState, useEffect } from 'react';
import type { AppProps } from 'next/app';
import { ThemeProvider } from '@mui/material';
import { StylesProvider } from '@mui/styles';
import CssBaseline from '@mui/material/CssBaseline';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import axios from 'axios';

import theme from '@/styles/theme';
import AuthProvider, { useAuthDispatchContext } from '@/modules/auth/providers';
import { ResDataType } from '@/pages/api/auth';
import { Logger } from '@/utils';

dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);

const App = ({ Component, pageProps }: AppProps): JSX.Element => {
  const dispatch = useAuthDispatchContext();
  const [authInfo, setAuthInfo] = useState<ResDataType>(null);

  useEffect(() => {
    // Remove the server-side injected CSS.(https://material-ui.com/guides/server-rendering/)
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
  }, []);

  useEffect(() => {
    // Auth
    axios
      .get('/api/auth/')
      .then((ret: { data: ResDataType }) => {
        setAuthInfo({
          sid: ret?.data?.sid,
          authencated: ret?.data?.authencated,
          secToken: ret?.data?.secToken,
        });
      })
      .catch((err) => {
        Logger.warn(err);
      });
  }, [dispatch]);

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <AuthProvider authInfo={authInfo}>
          <CssBaseline />
          <GlobalStyle />
          <Component {...pageProps} />
        </AuthProvider>
      </ThemeProvider>
    </StylesProvider>
  );
};

export default App;
